<template>
  <div class="not-found">
    <not-found-logo />

    <div class="q-my-md row justify-center">
      <q-btn
        to="/"
        :label="$t('Onboard')"
        color="light-blue-9"
        class="q-mr-sm"
      />
    </div>
  </div>
</template>

<script>
// Components
import NotFoundLogo from '../components/not-found-logo/NotFoundLogo.vue'

export default {
  name: 'NotFound',
  components: {
    NotFoundLogo
  },
  data () {
    return {
      user: JSON.parse(localStorage.getItem('userData'))
    }
  }
}
</script>

<style>
.not-found {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1020;
  background-color:#141019;
  background: radial-gradient(at 50% -20%, #908392, #0d060e) fixed;
}
</style>
